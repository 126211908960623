*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  min-height: 100%;
}
a{
  text-decoration: none;
  color: black;
}