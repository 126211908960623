.home{
    width: 100%;
}

.home-header{
    width: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.home-header h2{
    margin-top: 10%;
    font-size: 45px;
    color: white;
}

.home-header__btn{
    cursor: pointer;
    width: 400px;
    height: 70px;
    background-color: transparent;
    font-size: 22px;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    border: 3px solid white;
    border-radius: 30px;
    transition: 0.4s;
}

.home-deal-block{
    background: black;
    padding: 100px ;
    display: flex;
    justify-content: center;
    gap: 10%;
}

.deal-img{
    width: 40%;
    height: auto;
    object-fit: contain;
}

.deal-advantages{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.advantage-item{
    color: white;
    display: flex;
    align-items: center;
    font-size: 23px;
    display: flex;
    gap: 10px;
}

.advantage-text{
    font-size: 49px;
}


.home-offers{
    padding: 40px;
    min-height: 300px;
    display: flex;
    justify-content: space-evenly;
}

.home-offers .offer-item{
    width: 20%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 260px;
}

.offer-item__text{
    padding: 10px 0;
    text-align: center;
    font-size: 18px;
    color: white;
    font-weight: 500;
}

.offer-item__btn{
    cursor: pointer;
    width: 150px;
    padding: 5px;
    height: 50px;
    background-color: transparent;
    font-size: 15px;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    border: 3px solid white;
    border-radius: 30px;
    transition: 0.4s;
    margin-bottom: 20px;
}
  
@media (hover: hover) {
    .offer-item__btn:hover{
        background-color: white;
        transition: 0.4s;
        color: black;
    }
    .home-header__btn:hover{
        background-color: white;
        transition: 0.4s;
        color: black;
    }
}

@media (max-width:940px){
    .home-offers{
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
    .home-offers .offer-item{
        width: 50%;
        min-height: 200px;
    }
    .advantage-text{
        font-size: 40px;
    }
}

@media (max-width:870px){
    .home-header{
        min-height: 500px;
    }
}

@media (max-width:750px){
    .home-header{
        min-height: 400px;
    }   
    .home-header h2{
        font-size: 35px;
    }
    .home-header__btn{
        width: 300px;
        font-size: 20px;
        height: 50px;
    }
    .home-deal-block{
        height: 500px;
    }
    .advantage-text{
        font-size: 30px;
    }
    .advantage-item img{
        width: 50px;
    }
}

@media (max-width:650px){
    .home-deal-block{
        height: auto
    }
    .advantage-text{
        font-size: 28px;
    }
    .home-offers .offer-item{
        height: 400px;
    }
}

@media (max-width:605px){
    .deal-advantages{
        gap: 15px;
    }
    .home-deal-block{
        padding: 50px;
    }
    .home-offers .offer-item{
        height: 350px;
    }
}

@media (max-width:585px){
    .home-header{
        min-height: 300px;
    }
    .home-header h2{
        font-size: 30px;
    }
    .home-header__btn{
        width: 250px;
        font-size: 17px;
        height: 50px;
    }
    .advantage-text{
        font-size: 22px;
    }
    .advantage-item img{
        width: 35px;
    }
    .home-offers .offer-item{
        height: 300px;
    }
}

@media (max-width:470px){
    .home-header{
        min-height: 250px;
    }
    .home-header h2{
        font-size: 24px;
    }
    .home-header__btn{
        width: 200px;
        font-size: 14px;
        height: 40px;
    }
    .home-offers .offer-item{
        max-width: 60%;
        height: auto;
        min-height: 260px;
    }
}

@media (max-width:420px){
    .home-deal-block{
        padding: 25px;
        min-height: 250px;
    }
    .advantage-text{
        font-size: 20px;
    }
    .advantage-item img{
        width: 25px;
    }
    .home-offers .offer-item{
        min-height: 240px;
        width: 30%;
    }
}

@media (max-width:360px){
    .home-header{
        min-height: 220px;
    }
    .home-header h2{
        font-size: 20px;
    }
    .home-header__btn{
        width: 170px;
        font-size: 12px;
        height: 35px;
    }
    .home-deal-block{
        padding: 0 10px 0 30px;
        align-items: center;
        justify-content: center;
    }
    .deal-advantages{
        width: 90%;
    }
}

@media (max-width:330px){
    .advantage-text{
        font-size: 18px;
    }
    .advantage-item img{
        width: 20px;
    }   
}

@media (max-width:315px){
    .advantage-text{
        font-size: 17px;
    }
    .advantage-item img{
        width: 20px;
    }   
}
