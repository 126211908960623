.site-footer
{
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
  position: static;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer-contact hr{
    margin-top: 20px;
}

.contact-redirect{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-contact__btn{
  cursor: pointer;
  width: 400px;
  height: 70px;
  background-color: transparent;
  font-size: 22px;
  text-transform: uppercase;
  color: #bd4040;
  font-weight: 600;
  border: 3px solid #bd4040;
  border-radius: 30px;
  transition: 0.4s;
}

.footer-container__columns{
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0.5vw;
    flex-wrap: wrap;
}

.footer-container__columns .footer-column__item{
    max-width: 500px;
}

.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#bd4040;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#bd4040;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373;
  transition: 0.3s;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0;
  text-align: center;
  margin-top: 20px;
}

@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}

@media (max-width:767px)
{
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
  .footer-container__columns{
    padding: 0px 15vw;
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}

@media (hover: hover) {
  .footer-contact__btn:hover{
    background-color: #bd4040;
    transition: 0.8s;
    color: #26272b;
  }
  .footer-links a:active,.footer-links a:focus,.footer-links a:hover{
  color:#bd4040;
  text-decoration:none;
  transition: 0.3s;
  }
  .social-icons a.facebook:hover{
    background-color:#3b5998
  }
  .social-icons a.twitter:hover{
    background-color:#00aced
  }
  .social-icons a.linkedin:hover{
    background-color:#007bb6
  }
  .social-icons a.dribbble:hover{
    background-color:#ea4c89
  }
}

@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}

@media (max-width:600px)
{
  .footer-contact__btn{
    width: 300px;
    height: 60px;
  }
}

@media (max-width:425px){
  .footer-contact__btn{
    width: 250px;
    height: 55px;
    font-size: 20px;
  }
}

@media (max-width:400px){
  .footer-container .footer-container__columns{
    flex-direction: column;
    align-items:center
  }
  .footer-container .footer-container__columns{
    text-align: center;
  }
  .footer-contact__btn{
    width: 200px;
    height: 50px;
    font-size: 18px;
  }
}