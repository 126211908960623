.list{
    height: 100%;
    color: #bd4040;
}

.list .bar{
    height: 60px;
    background: #bd4040;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
}

.bar a{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.bar hr{
    width: 440px;
    transform: translateY(1px);
    background: black;
    color: black;
    border: 1px solid black;
}

.bar-address{
    font-size: 20px;
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center;
}

.list-offers{
    padding-top: 50px;
}

.list-offers__head{
    text-align: center;
    font-size: 45px;
}

.list-offers__listing{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listing-filter{
    width: 500px;
    display: flex;
    justify-content: space-evenly;
}

.listing-filter .filter-item{
    width: 30%;
    display: block;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
}

@media (max-width:580px) {
    .bar-address{
        font-size: 16px;
    }
    .bar hr{
        width: 355px;
    }    
}

@media (max-width:520px) {
    .carousel-content{
        justify-content: center;
    }
    .slide{
        width: 90%;
    }
    .list .bar{
        padding: 0 15px;
    }   
    .bar-address{
        font-size: 14px;
    }   
    .bar hr{
        width: 310px;
    }    
    .list-offers__head{
        font-size: 35px;
    }
}

@media (max-width:375px) {
    .list .bar{
        padding: 0 10px;
    }   
    .bar-address{
        font-size: 12px;
    }
    .bar hr{
        width: 270px;
    }   
    .bar-address img{
        width: 25px;
    }
}