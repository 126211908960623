.carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-top: 40px;
}
  
.carousel-content {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    width: 600px; 
    height: 500px;
    overflow: hidden;
    border: 1px solid #bd4040;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    animation: fadeInRight 0.7s ease-in-out;
}
  
.carousel-img{
    margin: 0 auto;
    width: 80%;
    height: 70%;
}
.slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carousel-action{
    margin: 30px 0;
    display: flex;
    gap: 20px;
    align-items: center;
}

.prev,
.next {
    background-color: #bd4040;
    color: black;
    font-size: 18px;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 1;
}
  
.slide.inactive {
    display: none;
}
  
.slide.active {
    display: block;
}

.carousel-contact-btn{
    cursor: pointer;
    width: 40%;
    height: 40px;
    background-color: transparent;
    font-size: 17px;
    text-transform: uppercase;
    color: #bd4040;
    font-weight: 600;
    border: 3px solid #bd4040;
    border-radius: 30px;
    transition: 0.8s;
    margin-top: 15px;
}

@media (hover: hover) {
    .carousel-contact-btn:hover{
        background-color: #bd4040;
        transition: 0.8s;
        color: black;
    }
}

@media (max-width:650px){
    .carousel-img {
        width: 100%;
        height: 60%;
    }
    .slide{
        width: 450px;
        height: 400px;
    }
}

@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(300px);
    }
    to {
      opacity: 1;
    }
}

@media (max-width:420px){
    .carousel-contact-btn{
        font-size: 15px;
    }   
    .slide p{
        font-size: 14px;
    }
}