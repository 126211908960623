.about-header{
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    min-height: 600px;
}

.about-header hr{
    width: 100%;
    height: 4px;
    border-color: white;
    background: white;
}

.about-header__address{
    display: flex;
    align-items: center;
    min-height: 100px;
    max-width: 80%;
    margin-top: 10%;
}

.about-header__address img{
    width: 60px;
    transform: translateY(-10px);
}

.about-header__address h2{
    font-size: 45px;
    color: white;
    width: 100%;
}

.marquee {
    min-height: 60px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
  
.marquee div {
    display: block;
    width: 200%;
    height: auto;
    position: absolute;
    overflow: hidden;
    animation: marqueeLeft 5s linear infinite;
}

.marquee:nth-child(even) div{
    animation: marqueeRight 5s linear infinite;
}

.marquee h2{
    float: left;
    width: 50%;
}

.marquee:nth-child(even) h2{
    float: right;
    width: 50%;
}

@keyframes marqueeLeft {
    0% { left: 0; }
    100% { left: -100%; }
}

@keyframes marqueeRight {
    0% { left: -100%; }
    100% { left: 0; }
}


.about h2{
    font-size: 45px;
}

.about-offer{
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-size: 50px;
}

.about-trust{
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 50px;
    background-color: black;
    position: relative;
}

.about-trust__img{
    width: 650px;
    height: 100%;
    position: absolute;
    filter: brightness(0.5);
}  
  
@media (max-width:1335px){
    .about-header__address a{
        max-width: 550px;
    }
}


@media (max-width:960px){
    .about-header, .about-offer {
        min-height: 500px;
    }
}

@media (max-width:730px){
    .about-header__address a {
        max-width: 400px;
    }
    .about-header__address h2{
        font-size: 35px;    
    }
    .about-trust__img{
        width: 100%;
        height: auto;
        bottom: 10px;
    }
}

@media (max-width:630px){
    .about-header, .about-offer {
        min-height: 450px;
    }    
    .about-trust__img{
        width: 90%;
    }
    .about-trust{
        justify-content: start;
    }
}

@media (max-width:570px){
    .about-header__address h2{
        font-size: 30px;    
    }    
    .about-offer h2{
        font-size: 35px;
    }
    .about-trust__img{
        bottom: 0;
        top: 70px;
    }
}

@media (max-width:510px){
    .about-header__address h2{
        font-size: 25px;    
    }
    .about-header__address a{
        max-width: 290px;
    }
}

@media (max-width:485px){
    .about-header__address img{
        width: 45px;
    }
    .about-header, .about-offer {
        min-height: 400px;
    }
    .about-trust{
        min-height: 300px;
    }
}
@media (max-width:410px){
    .about-header__address h2 {
        font-size: 20px;
    }
    .about-header__address a{
        max-width: 230px;
    }
    .about-offer h2{
        font-size: 27px;
    }
    .about-header, .about-offer {
        min-height: 350px;
    }
    .about-trust__img{
        bottom: 0;
        top: 40px;
    }
    .about-trust{
        min-height: 250px;
    }
}
@media (max-width:350px){
    .about-header__address h2 {
        font-size: 18px;
    }    
    .about-header__address a {
        max-width: 210px;
    }
}
@media (max-width:325px){
    .about h2{
        max-width: 80%;
    }
    .about-offer h2{
        font-size: 25px;
    }
    .about-header, .about-offer {
        min-height: 300px;
    }
    .about-trust{
        min-height: 200px;
    }
    .about-header__address img{
        width: 35px;
    }
    .about-header__address h2 {
        font-size: 16px;
    }    
    .about-header__address a {
        max-width: 190px;
    }
    .about-header hr{
        width: 80%;
    }
}
