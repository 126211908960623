.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #26272b;
    color: #bd4040;
}

.contact-info{
    padding-top: 40px;
}

.contact-info p{
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 20px;
}  

.contact-info hr{
    width: 100%;
    background-color: #bd4040;
    border: 1px solid #bd4040;
}  

  .contact-form {
    width: 80%;
    margin-top: 60px;
    max-width: 600px;
    margin-bottom: 30px;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form textarea {
    height: 150px;
  }

  .contact-form input, .contact-form textarea{
    background-color: gray;
    border: 1px solid gray;
  }

  .contact-form input::placeholder, #message::placeholder{
    font-family: Arial, Helvetica, sans-serif; 
    color: black;
    font-weight: 600;
}
  
.contact-form button {
    padding: 10px 20px;
    background-color: transparent;
    color: #bd4040;
    border: 3px solid #bd4040;
    border-radius: 20px;
    font-size: 20px;
    transition: 0.8s;
    cursor: pointer;
    font-weight: 600;
}

@media (hover: hover) {
    .contact-form button:hover{
        background-color: #bd4040;
        color: #26272b;
        transition: 0.8s;
    }
}
  
.contact-info {
    width: 80%;
    max-width: 600px;
}
  
.contact-info h2 {
    margin-bottom: 20px;
}
  
.contact-info p {
    margin-bottom: 10px;
}
  

.contact-page ~ footer .footer-contact__btn {
    display: none;
}


@media (max-width:470px) {
    .contact-info p{
        font-size: 18px;
    }
}

@media (max-width:380px) {
    .contact-info p{
        font-size: 16px;
    }
    .contact-info{
        width: 85%;
    }
}