.counter {
    font-size: 30px;
    margin: auto 0;
    z-index: 1;
    color: #65f221;
    border: 5px solid #65f221;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

#count {
    animation: count 1.5s linear infinite;
}

@keyframes count {
    from {
      content: '0';
    }
    to {
      content: '100';
    }
}

@media (max-width:630px){
    .counter{
        margin-bottom: 70px;
    }
}

@media (max-width:570px){
    .counter{
        font-size: 25px;
        margin-bottom: 0;
        margin-top: 100px;
        width: 170px;
        height: 170px;
    }
}

@media (max-width:450px){
    .counter{
        font-size: 20px;
        margin-top: 80px;
        width: 150px;
        height: 150px;
    }   
}

@media (max-width:410px){
    .counter{
        font-size: 18px;
        margin-top: 50px;
        width: 120px;
        height: 120px;
    }   
}
